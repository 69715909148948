const observer = lozad('.lazy'); // lazy loads elements with default selector as '.lozad'
var lazyCount=0;
observer.observe();

$(document).ready(function() {
    observer.observe();     
    lazyCount = $('.lazy').length;
});

/*$(document).on('ajaxStop', function() {
    observer.observe(); 
});*/

var muObserver = new MutationObserver(function(mutations) {
    if($('.lazy').length != lazyCount){
        observer.observe();
        lazyCount = $('.lazy').length;
    }
});

muObserver.observe($('body')[0], { childList: true, subtree: true });