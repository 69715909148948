function adBlockNotDetected(blockId) {
    if (blockId) {
        element = document.getElementById(blockId);
        element.parentNode.removeChild(element);
        return;
    }

    var _elements = document.querySelectorAll('[id^="nb-"]');
    _elements.forEach(function(element) {
        element.parentNode.removeChild(element);
    });
}

function adBlockDetected(blockId) {
    if (blockId) {
        element = document.getElementById(blockId);
        renderIfVisible(element.getAttribute('data-id'), true);
        var _el = element.parentNode.querySelectorAll("iframe[data-src]")[0];
        if (_el instanceof HTMLIFrameElement) {
            element.parentNode.removeChild(_el);
        }
        return;
    }

    // show ads + remove iframes
    var _elements = document.querySelectorAll('[id^="nb-"]');
    _elements.forEach(function(element) {
        renderIfVisible(element.getAttribute('data-id'), true);
        var _el = element.parentNode.querySelectorAll("iframe[data-src]")[0];
        if (_el instanceof HTMLIFrameElement) {
            element.parentNode.removeChild(_el);
        }
    });
}

justDetectAdblock.detectAnyAdblocker().then(function(detected) {
    _vrReload = false;
    if(detected){
        // an adblocker is detected
        _vrReload = true;
        adBlockDetected();
    } else {
        adBlockNotDetected();
    }
});

function renderNB() {
    if (typeof aaNB !== 'undefined' && aaNB.constructor === Array) {
        aaNB.forEach(function(element) {
            var el = document.getElementById('nb-' + element.idzone),
                elChildUrl,
                elChildImg;
            if (el) {
                var elChildUrl = document.createElement("a");
                elChildUrl.href = element.url;
                elChildUrl.setAttribute('target', '_blank');
                elChildUrl.setAttribute('rel',el.getAttribute('data-rel'));
                el.appendChild(elChildUrl);

                var elChildImg = document.createElement("img");
                if (el.getAttribute('data-lazy')) {
                    elChildImg.setAttribute('data-src', element.imgurl);
                    elChildImg.className = 'lazy';
                } else {
                    elChildImg.src = element.imgurl;
                }
                elChildUrl.appendChild(elChildImg);
            }
        });
    }
}

function getJSAtOnload(zone) {
    url = atob(_uNB);
    var element = document.createElement("script");
    element.src = url.replace('formats',zone).replace('tags',_tNB +',' + _sNB).replace('subid',_sNB);
    document.body.appendChild(element);
}

var renderIfVisible = (function() {
    function isVisible(ele) {
        return !!( ele.offsetWidth || ele.offsetHeight || ele.getClientRects().length );
    }

    var isInViewport = function (elem) {
        var bounding = elem.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    return function(id, defineCallback) {
        var element = document.getElementById("nb-" + id);
        if (isVisible(element) && isInViewport(element)) {
            getJSAtOnload(element.getAttribute('data-id'));
            return true;
        } else if (defineCallback) {
            var fun = function() {
                if (renderIfVisible(id, false)) {
                    window.removeEventListener('resize', fun);
                    window.removeEventListener('scroll', fun);
                }
            };
            window.addEventListener('scroll', fun);
            window.addEventListener('resize', fun);
        }
        return false;
    };
})();